<template>
  <div>
    <el-button @click='show'>回收站</el-button>
    <el-drawer title='回收站'
               :visible.sync='open'
               direction='rtl'
               size='70%'>
      <div class='default-table' style='padding-left: 10px;'>
        <div>
          <el-form :model='searchCondition' label-suffix='：'>
            <el-form-item label=''>
              <artist-search @handleSelect='chooseArtist'></artist-search>
              <!--              <el-input v-model='searchCondition.nickname' clearable></el-input>-->
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' @click='handleQuery' :disabled='loadingStatus'
                         :loading='loadingStatus'>搜索
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <!--    渲染表格-->
        <el-table :data='dataList' @sort-change='changeTableSort'>
          <el-table-column label='数据ID' prop='id' width='88' sortable='custom'></el-table-column>
          <el-table-column label='排期' prop='start_at' sortable='custom'>
            <template slot-scope='{row}'>
              {{ $utils.parseTime(row.start_at, '{y}年{m}月{d}日') }}【{{ row.nickname || '' }}】{{ row.platform_name || ''
              }} {{ row.theme || '' }}
              <el-tag effect='plain'> 负责人：{{ row.leader_name }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label='创建时间' prop='created_at' width='120' sortable='custom'
                           show-overflow-tooltip></el-table-column>
          <el-table-column label='删除时间' prop='deleted_at' width='140' sortable='custom'
                           show-overflow-tooltip></el-table-column>
          <el-table-column label='操作' width='100' align='center'>
            <template slot-scope='{row}'>
              <el-button type='text' icon='el-icon-refresh-left' @click='handleRestore(row)'>恢复</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--        <m-table :showIndex='true' :offset='offset' max-height='800' :tableData='dataList' :columns='columns'-->
        <!--                 :loading='loadingStatus' @selection-change='handleSelectionChange' border>-->
        <!--          <el-table-column label='操作' width='100' align='center'>-->
        <!--            <template slot-scope='{row}'>-->
        <!--              <el-button type='text' icon='el-icon-refresh-left' @click='handleRestore(row)'>恢复</el-button>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
        <!--        </m-table>-->
        <div style='display: flex;flex-direction: row;gap: 5px;justify-content: space-between;align-items: center'>
          <el-button type='text' icon='el-icon-refresh' @click='getList' :disabled='loadingStatus'
                     :loading='loadingStatus'>刷新
          </el-button>
          <!--    渲染分页-->
          <m-pagination :total.sync='total' :limit.sync='pageData.page_size' :page.sync='pageData.current_page'
                        @pagination='getList' />
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import ArtistSearch from '@/components/artist/ArtistSearch'

export default {
  name: 'RecycleList',
  components: { ArtistSearch },
  props: {
    LbType: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      loadingStatus: false,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 是否显示弹出层
      open: false,
      dataList: [],
      total: 0,
      offset: 0,
      pageData: {
        page_size: 15,
        current_page: 1
      },
      orderBy: { deleted_at: 'desc', id: 'asc' },
      searchCondition: { artist_id: null, nickname: null },
      defaultColumns: [{ hide: false, prop: 'id', label: '序号', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'type', label: '排期类型', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'artist_id', label: '红人Id', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'nickname', label: '红人昵称', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'platform_code', label: '平台', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'progress_status', label: '进度状态', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'start_at', label: '开播开始时间', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'end_at', label: '开截止时间', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'theme', label: '主题', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'leader_id', label: '负责人', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'leader_name', label: '负责人', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'pit_num', label: '坑位数量', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'product_structure', label: '货品结构', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'deadline', label: '最晚选品初选时间', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'remark', label: '备注', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'platform_name', label: '平台名称', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'creator_id', label: '创建人ID', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'selected_qty', label: '选品数量', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'selected_time', label: '最后选品时间', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'created_at', label: '创建时间', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'updated_at', label: '修改时间', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'deleted_at', label: '删除时间', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'download_count', label: '导出次数', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'created_by', label: '创建人', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'updated_by', label: '修改人', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'live_time', label: '直播日期', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'overall_id', label: '统筹负责人ID', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'overall_name', label: '统筹负责人', width: 120, sortable: true, fixed: false }],
      columns: [
        { title: 'ID', value: 'id' },
        // { title: '排期类型', value: 'type' },
        { title: '红人昵称', value: 'nickname' },
        { title: '平台', value: 'platform_code' },
        { title: '主题', value: 'theme' },
        { title: '负责人', value: 'leader_name' },
        { title: '创建时间', value: 'created_at' },
        { title: '删除数据', value: 'deleted_at', width: '140px' }
      ]
    }
  },
  methods: {
    show() {
      this.open = true
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.multiple = !selection.length
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field]) {
          condition[field] = this.searchCondition[field]
        }
      })

      // if (this.searchCondition.date_range) {
      //   condition.date_range = this.searchCondition.date_range
      // }
      condition['type'] = this.LbType
      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderBy = orderSort
      this.handleQuery()
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
    },
    async getList() {
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()

      let { list, pages } = await this.$api.getLbScheduleRecycle(searchCondition)
      // let { list, pages } = await this.$api.getLbDressSelectionLog(searchCondition)

      this.$nextTick(() => {
        this.dataList = list
        this.total = pages.total
        this.offset = pages.offset
        this.pageData.current_page = pages.current_page
        this.pageData.page_size = pages.page_size

      })
      setTimeout(() => {
        this.loadingStatus = false
      }, 500)

    },
    handleRestore(row) {
      this.$confirm(`确认【恢复】已删除排期【数据ID：${row.id}】？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let id = await this.$api.restoreLbSchedule({ id: row.id })
        if (id) {
          this.$message.success('恢复成功！')
          await this.getList()
          this.$emit('changed')
        } else {
          this.$message.error('未知错误,请联系管理员')
        }
      })

    },

    async chooseArtist(item) {
      if (item) {
        this.searchCondition.artist_id = item.id
        // this.searchCondition.nickname = item.nickname
      } else {
        this.searchCondition.artist_id = null
        // this.searchCondition.nickname = null
      }
      this.handleQuery()
    }
  }
}
</script>

<style scoped>

</style>
